<template>
  <div id="staff-list">
    <v-dialog v-model="addRecordPrompt" persistent max-width="600px">
      <add-sma-online-payment
        pageDescription="Add SMA Online Payment"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="hideAddRecordPrompt"
        v-if="addRecordPrompt"
      ></add-sma-online-payment>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>

        <v-container class="py-0" v-if="SearchFormFlag">
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    Zone
                    <small>(Optional)</small>
                  </h6>
                </label>
                <!-- <v-text-field
                  v-model="ZoneCodeText"
                  hint="Zone"
                  type="text"
                  required
                  dense
                  outlined
                ></v-text-field> -->
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  :loading="ZoneCodeOptionsLoading"
                  v-model="ZoneCode"
                  hint="Select zone"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>LOM <small> (optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="LomCode"
                  :rules="LomCodeRules"
                  :items="LomCodeOptions"
                  :loading="LomCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-container class="py-0" v-if="ResultFlag && rows.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ rows.length }} records found.</h3>
              <p></p>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="ExcelDownloadFlag"
                color="info"
                @click.prevent="
                  generateExcel(
                    rows,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="mr-4"
                ><v-icon dark> mdi-download </v-icon> Download</v-btn
              >
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :show-select="FloatingButtonOptions.ShowSelectFlag"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          icon
                          size="25px"
                          color="blue"
                          @click="previewData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.ActiveStatus == 2"
                        >
                          mdi-clipboard-text-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                    -->
                  <v-tooltip bottom v-if="item.ChangeLomFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Change Member Lom </span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="item.OrdersReceiptPath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.OrdersReceiptPath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-pdf </v-icon>
                      </v-btn>
                    </template>
                    <span> Download </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
    <v-dialog v-model="editRecordPrompt" persistent max-width="80%">
      <senior-member-edit
        :editRecordPrompt="editRecordPrompt"
        :recordData="recordData"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        v-if="editRecordPrompt"
      ></senior-member-edit>
    </v-dialog>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import AddSmaOnlinePayment from "@/view/pages/erp/seniors/AddSmaOnlinePayment.vue";
import SeniorMemberEdit from "@/view/pages/erp/seniors/SeniorMemberEdit.vue";

export default {
  mixins: [common],
  components: {
    AddSmaOnlinePayment,
    SeniorMemberEdit,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      SearchFormFlag: true,
      DownloadFlag: true,
      TableLoadingFlag: false,

      rows: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      SpeedDialFlag: false,
      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      recordData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      ExcelDownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      PaymentId: "",
      PaymentIdRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      ZoneCodeText: "",
      FloatingButtonOptions: {},
      RoleId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        // RoleId = 4;
        this.RoleId = RoleId;

        this.getZoneCodeOptions();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      this.getLomCodeOptions();
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      // this.getTableRecords();
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "senior_members",
        Action: "list_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      // this.getZone();
      // this.getLomCodeOptions();
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var CurrentYearId = this.CurrentYearId;
      console.log(", CurrentYearId=" + CurrentYearId);
      this.LoadingFlag = true;
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options-v2";
      var selectbox1_conditions_array = {
        UserInterface: 8,
        YearCode: CurrentYearId,
        NotAvailable: 1,
        AllZones: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.CurrentYearId;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "") {
        this.LomCode = "";
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options-v2";
        if (this.RoleId == 9) {
          var selectbox1_conditions_array = {
            UserInterface: 7,
            YearCode: YearCode,
            ZoneCode: ZoneCode,
            AllOption: 0,
          };
          this.getSelectBoxOptions(
            selectbox1_source,
            selectbox1_destination,
            selectbox1_conditions_array,
            selectbox1_url
          );
        } else {
          var selectbox1_conditions_array = {
            UserInterface: 3,
            YearCode: YearCode,
            ZoneCode: ZoneCode,
            AllOption: 1,
          };
          this.getSelectBoxOptions(
            selectbox1_source,
            selectbox1_destination,
            selectbox1_conditions_array,
            selectbox1_url
          );
        }
      } else {
        var message = "";
        this.toast("error", message);
      }
    },
    searchForm() {
      console.log("searchForm is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      var FromDate = this.FromDate;
      var ToDate = this.ToDate;

      if (validate1 && FromDate != "" && ToDate != "") {
        // this.SearchFlag = true;
        this.LoadingFlag = true;
        this.rows = [];
        this.ExcelFileName = "";
        this.ExcelDownloadFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/assign/senior-members/list";
        var upload = {
          UserInterface: 3,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          PaymentId: this.PaymentId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.table_content;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.rows = records.TableData;

              thisIns.ExcelDownloadFlag = options.ExcelDownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.recordData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    getZone() {
      console.log("getZone Called");
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/zone/show";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.rows = [];

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.ZoneCode = records.MemberZoneId;
            thisIns.ZoneCodeText = records.ZoneDisplayText;
            thisIns.getLomCodeOptions();
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.toast("error", error);
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
        });
    },
  },
  beforeMount() {
    var todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);

    this.FromDate = todayDate;
    this.ToDate = todayDate;

    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#staff-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>