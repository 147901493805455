import { render, staticRenderFns } from "./ZoneSeniorMembersList.vue?vue&type=template&id=b87b0090&"
import script from "./ZoneSeniorMembersList.vue?vue&type=script&lang=js&"
export * from "./ZoneSeniorMembersList.vue?vue&type=script&lang=js&"
import style0 from "./ZoneSeniorMembersList.vue?vue&type=style&index=0&id=b87b0090&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports